import styled from 'styled-components'

import { MainTitle, MainSubtitle, BodyText } from '../../common/text-styled'
import { media } from '../../../constants/media'

export const Container = styled.div`
  text-align: left;
  margin: 0 40px;
  @media ${media.medium.query} {
    margin: 0 20px;
  }
`;

export const PageTitle = styled(MainTitle)`
  text-align: center;
  @media ${media.medium.query} {
    text-align: left;
  }
`;

export const SectionTitle = styled(MainSubtitle)`
  margin-top: 45px;
  @media ${media.medium.query} {
    margin-top: 90px;
  }
`;

export const Text = styled(BodyText)`
`;

export const A = styled.a`
  text-decoration: underline;
`;
