import React from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { PrivacyPolicy } from '../components/pages/privacy-policy/privacy-policy-component'

const PrivacyPage = () => (
  <Layout>

    <SEO
      title="Privacy Policy"
      keywords={['privacy', 'privacy policy', 'cooking', 'traveling', 'travel' ]}
    />

    <PrivacyPolicy />

  </Layout>
)

export default PrivacyPage;