import React from 'react'

import * as S from './privacy-policy-styled'

export const PrivacyPolicy = () => (
  <S.Container>
    <S.PageTitle>Privacy Policy</S.PageTitle>

    <S.Text>
      Effective date: November 19, 2019
    </S.Text>
    <S.Text>
      Just Right Solutions LLC ("us", "we", or "our") operates the https://www.globalgrubbr.com website (hereinafter referred to as the "Service")
    </S.Text>
    <S.Text>
      This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. The Privacy Policy for Just Right Solutions LLC has been created with the help of Privacy Policy Generator.
    </S.Text>
    <S.Text>
      We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.
    </S.Text>

    <S.SectionTitle>Definitions</S.SectionTitle>
    <S.Text>
      <b>Service</b>: the https://www.globalgrubbr.com website operated by Just Right Solutions LLC
    </S.Text>
    <S.Text>
      <b>Personal Data</b>: data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
    </S.Text>
    <S.Text>
      <b>Usage Data</b>: data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
    </S.Text>
    <S.Text>
      <b>Cookies</b>: small files stored on your device (computer or mobile device).
    </S.Text>

    <S.SectionTitle>Information Collection and Use</S.SectionTitle>
    <S.Text>
      While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to: email address, Cookies and Usage Data
    </S.Text>
    <S.Text>
      We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
    </S.Text>
    <S.Text>
      We use cookies and similar tracking technologies for advertisting to display relevant ads, to track the activity on our Service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
    </S.Text>

    <S.SectionTitle>Use of Data</S.SectionTitle>
    <S.Text>
      We use the collected data for various purposes: To provide and maintain the Service, to notify you about changes to our Service, to allow you to participate in interactive features of our Service when you choose to do so, to provide customer care and support, to provide analysis or valuable information so that we can improve the Service, to monitor the usage of the Service, to detect, prevent and address technical issues, to provide you with news, special offers and general information about other goods, services and events which are related to our Service.
    </S.Text>

    <S.SectionTitle>Retention of Data</S.SectionTitle>
    <S.Text>
      We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
    </S.Text>
    <S.Text>
      We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
    </S.Text>

    <S.SectionTitle>Transfer of Data</S.SectionTitle>
    <S.Text>
      Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
    </S.Text>
    <S.Text>
      If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.
    </S.Text>
    <S.Text>
      Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
    </S.Text>
    <S.Text>
      Just Right Solutions LLC will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
    </S.Text>

    <S.SectionTitle>Disclosure of Data</S.SectionTitle>
    <S.Text>
      We may disclose your Personal Data in the good faith belief that such action is necessary to:
      To comply with a legal obligation, 
      to protect and defend the rights or property of Just Right Solutions LLC, 
      to prevent or investigate possible wrongdoing in connection with the Service, 
      to protect the personal safety of users of the Service or the public, 
      to protect against legal liability 
    </S.Text>
    <S.Text>
      As an European citizen, under GDPR, you have certain individual rights. You can learn more about these guides in the GDPR Guide.
    </S.Text>

    <S.SectionTitle>Security of Data</S.SectionTitle>
    <S.Text>
      The security of your Personal Data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
    </S.Text>

    <S.SectionTitle>Rights of Data Under General Data Protection Regulation (GDPR)</S.SectionTitle>
    <S.Text>
      You can request a copy of your Personal Data. You can access and ensure the accuracy of the Personal Data we have on you. You can also have us delete or update your Personal Data. You have the right to object, restrict, or withdraw consent from processing your Personal Data. You may also create a complaint with your local local data protection authority regarding our Service and handling of your Personal Data.
    </S.Text>

    <S.SectionTitle>Service Providers</S.SectionTitle>
    <S.Text>
      We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
    </S.Text>

    <S.SectionTitle>Advertisements</S.SectionTitle>
    <S.Text>
      Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Just Right Solutions LLC and does not cover the use of cookies by any advertisers.
    </S.Text>

    <S.SectionTitle>E-mail Addresses</S.SectionTitle>
    <S.Text>
      We may collect your e-mail address if you voluntarily provide it to us. We will use your e-mail address to send you information regarding our Serivce and other products or information that we believe may be of interest to you.
    </S.Text>
    <S.Text>
      Any e-mail we send may contain a tracking pixel to allow our Service to track and analyze when e-mails are opened and which links within the e-mail have been clicked.
    </S.Text>
    <S.Text>
      You may opt out from receiving any e-mails from us by clicking the "unsubscribe" link found in any of our e-mails.
    </S.Text>

    <S.SectionTitle>Affiliate Disclosure</S.SectionTitle>
    <S.Text>
      Our Service may use affiliate links. Clicking on an affiliate link may use a cookie to track a related purchase for the purpose of commission.
    </S.Text>

    <S.SectionTitle>Remarketing Ads</S.SectionTitle>
    <S.Text>
      Our Service uses the remarketing services to advertise on third-party websites (including Google, Facebook, and Pinterest) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors (including Google, Facebook and Pinterest) use cookies to serve ads based on someone's past visits. Of course, any data collected will be used in accordance with our own privacy policy and the respective third-party's privacy policy.
    </S.Text>
    <S.Text>
      You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.
    </S.Text>

    <S.SectionTitle>Aggregated Statistics</S.SectionTitle>
    <S.Text>
      Our Service may collect statistics about the behavior of visitors to its website. Just Right Solutions LLC may display this information publicly or provide it to others. However, Just Right Solutions LLC does not disclose your personally-identifying information.
    </S.Text>

    <S.SectionTitle>Links To External Sites</S.SectionTitle>
    <S.Text>
      Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.
    </S.Text>
    <S.Text>
      We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
    </S.Text>

    <S.SectionTitle>Children</S.SectionTitle>
    <S.Text>
      Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
    </S.Text>

    <S.SectionTitle>Privacy Policy Changes</S.SectionTitle>
    <S.Text>
      Just Right Solutions LLC may change its Privacy Policy from time to time, and in Just Right Solutions LLC's sole discretion. Just Right Solutions LLC encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
    </S.Text>

    <S.SectionTitle>Contact Information</S.SectionTitle>
    <S.Text>
      If you have any questions about this Privacy Policy, please contact us by email: <S.A href="mailto:info@globalgrubbr.com">info@globalgrubbr.com</S.A>
    </S.Text>
  </S.Container>
)